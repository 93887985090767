import React from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import ShippingCompanies from "../components/orders/ShippingCompanies";

function ShippingCompaniesPage() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          links={[
            { title: "Dashboard", href: "/" },
            { title: "Orders", href: "/orders" },
            {
              title: "Shipping Companies",
            },
          ]}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ShippingCompanies />
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(ShippingCompaniesPage, [ROLES.SUPER_ADMIN, ROLES.ADMIN])
);
