import React from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import PaymentMethods from "../components/orders/PaymentMethods";

function PaymentMethodsPage() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          links={[
            { title: "Dashboard", href: "/" },
            { title: "Orders", href: "/orders" },
            {
              title: "Payment Methods",
            },
          ]}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <PaymentMethods />
      </Grid>
    </Grid>
  );
}

export default withAuth(withRoles(PaymentMethodsPage, [ROLES.SUPER_ADMIN]));
