export const locales = {
  af: "af-ZA",
  ar: "ar",
  bg: "bg-BG",
  ca: "ca-AD",
  cs: "cs-CZ",
  cy: "cy-GB",
  da: "da-DK",
  de: "de-DE",
  gr: "el-GR",
  en: "en-US",
  es: "es-ES",
  et: "et-EE",
  eu: "eu",
  fa: "fa-IR",
  fi: "fi-FI",
  fr: "fr-FR",
  gl: "gl-GL",
  he: "he-IL",
  hi: "hi-IN",
  hr: "hr-HR",
  hu: "hu-HU",
  id: "id-ID",
  is: "is-IS",
  it: "it-IT",
  ja: "ja-JP",
  km: "km-KH",
  ko: "ko-KR",
  la: "la",
  lt: "lt-LT",
  lv: "lv-LV",
  mn: "mn-MN",
  nb: "nb-NO",
  nl: "nl-NL",
  nn: "nn-NO",
  pa: "pa-PK",
  pl: "pl-PL",
  pt: "pt-PT",
  ro: "ro-RO",
  ru: "ru-RU",
  sk: "sk-SK",
  sl: "sl-SI",
  sr: "sr-RS",
  sv: "sv-SE",
  th: "th-TH",
  tr: "tr-TR",
  uk: "uk-UA",
  vi: "vi-VN",
  zh: "zh-CN",
};

export const localeOptions = Object.entries(locales).map(([k, v]) => ({
  label: v,
  value: k,
}));
