import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { useNavigate, useParams } from "react-router-dom";
import { getWebsite } from "../store/actions/products";
import useProducts from "../store/hooks/useProducts";
import BackdropLoader from "../components/ui/BackdropLoader";
import { connect } from "react-redux";
import WebsiteSections from "../components/websites/WebsiteSections";

function WebsiteSectionsPage({ getWebsite }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { website, loading } = useProducts();
  useEffect(() => {
    getWebsite(id)
      .then((res) => {
        if (!res) {
          navigate("/websites");
        }
      })
      .catch((err) => {
        navigate("/websites");
      });
  }, [getWebsite, id, navigate]);

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading || !website?.id} />
      {website?.id && !loading ? (
        <>
          <Grid item md={12} xs={12}>
            <AppWidgetSummary
              links={[
                { title: "Dashboard", href: "/" },
                { title: "Websites", href: "/websites" },
                { title: website?.domain || "" },
                { title: "Sections" },
              ]}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <WebsiteSections key={id} />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}

export default withAuth(
  withRoles(connect(null, { getWebsite })(WebsiteSectionsPage), [
    ROLES.SUPER_ADMIN,
  ])
);
