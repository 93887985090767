import { Grid } from "@mui/material";
import React from "react";
import ChangePasswordForm from "../components/auth/ChangePasswordForm";
import { ProfileTabs } from "../components/profile/constants";
import ProfileBasicInfo from "../components/profile/ProfileBasicInfo";
import ProfileForm from "../components/profile/ProfileForm";
import TabContainer from "../components/tabs/TabContainer";
import withAuth from "../hoc/withAuth";
import useAuth from "../store/hooks/useAuth";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";

function ProfilePage() {
  const { user } = useAuth();
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          links={[{ title: "Dashboard", href: "/" }, { title: "Profile" }]}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TabContainer
          tabs={ProfileTabs}
          sx={{ p: 2, alignItems: "flex-start" }}
        >
          <ProfileBasicInfo user={user} />
          <ProfileForm />
          <ChangePasswordForm />
        </TabContainer>
      </Grid>
    </Grid>
  );
}

export default withAuth(ProfilePage);
