import React, { useCallback, useEffect, useState } from "react";
import withRoles from "../hoc/withRoles";
import withAuth from "../hoc/withAuth";
import { ROLES } from "../data/roles";
import axios from "../apiClient";
import { toApiUrl } from "../utils/generic";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { Delete, MemoryOutlined } from "@mui/icons-material";
import BackdropLoader from "../components/ui/BackdropLoader";
import ListView from "../components/ui/ListView";
import SimpleDialog from "../components/dialogs/SimpleDialog";
import ReactJson from "react-json-view";
import Title from "../components/ui/Title";
import ConfirmationDialog from "../components/dialogs/ConfirmationDialog";

function CachePage() {
  const [data, setData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCache = useCallback(() => {
    setLoading(true);
    axios
      .get(toApiUrl("/cache"), { params: { count: 2000 } })
      .then((res) => {
        setData((prev) => res.data);
      })
      .catch((err) => setData([]))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchCache();
  }, [fetchCache]);

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          links={[{ title: "Dashboard", href: "/" }, { title: "Cache" }]}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ListView
          button
          title={
            <Title sx={{ justifyContent: "space-between" }}>
              <Title>{`${data?.length || 0} Entries`}</Title>
              <Tooltip title="Clear Cache/Sessions">
                <IconButton
                  disabled={loading || !data?.length}
                  onClick={() => setConfirm(true)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Title>
          }
          sx={{ maxHeight: "600px", overflowY: "auto" }}
          items={(data || []).map((entry, idx) => ({
            primary: typeof entry === "string" ? entry : JSON.stringify(entry),
            secondary: idx + 1,
            icon: <MemoryOutlined />,
            onClick: async () => {
              setLoading(true);
              axios
                .get(toApiUrl(`/cache/${entry}`))
                .then((res) => {
                  setSelectedEntry({ data: res.data, key: entry });
                })
                .catch((error) => setSelectedEntry(null))
                .finally(() => setLoading(false));
            },
          }))}
        />
      </Grid>
      <SimpleDialog
        disableBackdropClick={loading}
        open={!!selectedEntry}
        onCancel={() => {
          setSelectedEntry(null);
        }}
        onConfirm={async () => {
          setLoading(true);
          axios
            .post(toApiUrl(`/cache/${selectedEntry?.key}`), {
              data: selectedEntry?.data,
              expiration: selectedEntry?.expiration || undefined,
            })
            .then((res) => {
              setSelectedEntry(null);
            })
            .catch((error) => setSelectedEntry(null))
            .finally(() => setLoading(false));
        }}
      >
        {selectedEntry?.data ? (
          <ReactJson
            enableClipboard
            onEdit={(value) => {
              setSelectedEntry((prev) => ({
                ...prev,
                data: value.updated_src,
              }));
            }}
            onDelete={(value) => {
              setSelectedEntry((prev) => ({
                ...prev,
                data: value.updated_src,
              }));
            }}
            src={selectedEntry?.data || {}}
          />
        ) : null}
      </SimpleDialog>
      <ConfirmationDialog
        open={confirm}
        onCancel={() => setConfirm(false)}
        onConfirm={async () => {
          setLoading(true);
          axios
            .delete(toApiUrl("/cache"))
            .then(async (res) => {
              fetchCache();
            })
            .catch((err) => {})
            .finally(() => {
              setLoading(false);
              setConfirm(false);
            });
        }}
      />
    </Grid>
  );
}

export default withAuth(withRoles(CachePage, [ROLES.SUPER_ADMIN]));
