import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { useDispatch } from "react-redux";
import { getWebsites } from "../store/actions/products";
import BlogPosts from "../components/blogs/BlogPosts";

function BlogPostsPage({ identifier = "blog_posts" }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWebsites());
  }, [dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          links={[{ title: "Dashboard", href: "/" }, { title: "Blog" }]}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <BlogPosts key={identifier} identifier={identifier} />
      </Grid>
    </Grid>
  );
}

export default withAuth(withRoles(BlogPostsPage, [ROLES.SUPER_ADMIN]));
