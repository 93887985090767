import React from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import Suppliers from "../components/inventory/Suppliers";

function SuppliersPagew() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          links={[{ title: "Dashboard", href: "/" }, { title: "Suppliers" }]}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Suppliers />
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(SuppliersPagew, [ROLES.SUPER_ADMIN, ROLES.ADMIN])
);
