import { alpha, styled } from "@mui/material/styles";
import {
  Breadcrumbs,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

export default function AppWidgetSummary({
  type = "breadcrumb",
  links = [],
  maxItems = 5,
  title,
  total,
  icon,
  color = "primary",
  sx,
  children,
  ...other
}) {
  if (type === "breadcrumb") {
    return (
      <>
        <Breadcrumbs maxItems={maxItems}>
          {links.map((link) =>
            link.href ? (
              <MuiLink
                underline="hover"
                component={Link}
                to={link.href}
                key={link.title}
              >
                {link.title}
              </MuiLink>
            ) : (
              <Typography sx={{ color: "text.primary" }} key={link.title}>
                {link.title}
              </Typography>
            )
          )}
        </Breadcrumbs>
        <Divider sx={{ my: 1 }} />
      </>
    );
  }

  return (
    <Card
      sx={{
        py: 4,
        px: 3,
        boxShadow: 0,
        textAlign: "center",
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(
              theme.palette[color].dark,
              0
            )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
        }}
      >
        {icon}
      </StyledIcon>

      <Typography variant="h3">{total}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
      {children}
    </Card>
  );
}
