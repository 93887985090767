import React from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import Websites from "../components/websites/Websites";
// import WebsitesTimeSeries from "../components/websites/WebsitesTimeSeries";
function WebsitesPage() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          links={[{ title: "Dashboard", href: "/" }, { title: "Websites" }]}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Websites />
      </Grid>
      {/* <Grid item md={12} xs={12}>
        <WebsitesTimeSeries />
      </Grid> */}
    </Grid>
  );
}

export default withAuth(withRoles(WebsitesPage, [ROLES.SUPER_ADMIN]));
