import React, { useMemo } from "react";
import ListView from "../ui/ListView";
import {
  Inventory2,
  List,
  PeopleAlt,
  Settings,
  ShoppingBag,
  ShoppingCart,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "../../store/hooks/useAuth";
import { ROLES } from "../../data/roles";
import Title from "../ui/Title";

export default function ManagementActions({ items = [], title, icon }) {
  const navigate = useNavigate();
  const managementItems = useMemo(() => {
    if (!items?.length) {
      return [];
    }
    return items
      .filter(({ canAccess }) => canAccess())
      .map((item) => ({
        primary: item.title,
        icon: <List />,
        secondary: item.description,
        renderActions: () => (
          <IconButton onClick={() => navigate(item.to)}>
            <Settings />
          </IconButton>
        ),
      }));
  }, [items, navigate]);

  return (
    <ListView
      sx={{ height: "100%" }}
      title={
        <Title divider>
          {icon || <Settings sx={{ mr: 2 }} />}
          {title}
        </Title>
      }
      items={managementItems}
    />
  );
}

export const ManageStock = () => {
  const { user } = useAuth();
  if (
    !user ||
    [ROLES.ADMIN, ROLES.SUPER_ADMIN].indexOf(user.role.name) === -1
  ) {
    return null;
  }

  const items = [
    {
      title: "Suppliers",
      description: "Manage suppliers",
      canAccess: () => true,
      to: "/suppliers",
    },
    {
      title: "Inventory",
      description: "Manage inventory",
      canAccess: () => true,
      to: "/inventory",
    },
  ];

  return (
    <ManagementActions items={items} title="Stock" icon={<Inventory2 />} />
  );
};

export const ManageProducts = () => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  const items = [
    {
      title: "Products",
      description: "Manage products",
      canAccess: () => true,
      to: "/products/account",
    },

    {
      title: "Websites",
      description: "Manage websites",
      canAccess: () => user.role.name === ROLES.SUPER_ADMIN,
      to: "/websites",
    },
    {
      title: "Groups",
      description: "Manage groups",
      canAccess: () => user.role.name === ROLES.SUPER_ADMIN,
      to: "/products/groups",
    },
    {
      title: "Product Super Categories",
      description: "Manage product super categories",
      canAccess: () => user.role.name === ROLES.SUPER_ADMIN,
      to: "/products/supercategories",
    },
    {
      title: "Product Categories",
      description: "Manage product categories",
      canAccess: () => user.role.name === ROLES.SUPER_ADMIN,
      to: "/products/categories",
    },
    {
      title: "Product Brands",
      description: "Manage product brands",
      canAccess: () => user.role.name === ROLES.SUPER_ADMIN,
      to: "/products/brands",
    },
    {
      title: "Product Attributes",
      description: "Manage product attributes",
      canAccess: () => user.role.name === ROLES.SUPER_ADMIN,
      to: "/products/attribute-categories",
    },
  ];

  return (
    <ManagementActions items={items} title="Products" icon={<ShoppingBag />} />
  );
};

export const ManageOrders = () => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  const items = [
    {
      title: "Orders",
      description: "Manage orders",
      canAccess: () => true,
      to: "/orders",
    },
    {
      title: "Order Statuses",
      description: "Manage order statuses",
      canAccess: () => user.role.name === ROLES.SUPER_ADMIN,
      to: "/orders/statuses",
    },
    {
      title: "Shipping Companies",
      description: "Manage shipping companies",
      canAccess: () =>
        [ROLES.SUPER_ADMIN, ROLES.ADMIN].indexOf(user.role.name) !== -1,
      to: "/orders/shipping-companies",
    },
    {
      title: "Payment Methods",
      description: "Manage payment methods",
      canAccess: () => user.role.name === ROLES.SUPER_ADMIN,
      to: "/orders/payment-methods",
    },
    {
      title: "Coupons",
      description: "Manage coupons",
      canAccess: () =>
        [ROLES.SUPER_ADMIN, ROLES.ADMIN].indexOf(user.role.name) !== -1,
      to: "/orders/coupons",
    },
  ];

  return (
    <ManagementActions items={items} title="Orders" icon={<ShoppingCart />} />
  );
};

export const ManageUsers = () => {
  const { user } = useAuth();

  if (
    !user ||
    [ROLES.ADMIN, ROLES.SUPER_ADMIN].indexOf(user.role.name) === -1
  ) {
    return null;
  }

  const items = [
    {
      title: "Users",
      description: "Manage Platform Users",
      canAccess: () => user.role.name === ROLES.SUPER_ADMIN,
      to: "/users",
    },

    // {
    //   title: "Account Users",
    //   description: "Manage account users",
    //   canAccess: () =>
    //     user.role.name === ROLES.SUPER_ADMIN || user.role.name === ROLES.ADMIN,
    //   to: "/users/account",
    // },
    {
      title: "Newsletter",
      description: "Manage newsletter recipients",
      canAccess: () =>
        user.role.name === ROLES.SUPER_ADMIN || user.role.name === ROLES.ADMIN,
      to: "/newsletter",
    },
  ];

  return <ManagementActions items={items} title="Users" icon={<PeopleAlt />} />;
};

export const ManagementGrid = ({ gridOptions = { md: 12, xs: 12 } }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Title divider sx={{ mb: 2, mt: 2 }}>
          <Settings sx={{ mr: 2 }} /> Management
        </Title>
      </Grid>
      <Grid item {...gridOptions}>
        <ManageProducts />
      </Grid>
      <Grid item {...gridOptions}>
        <ManageOrders />
      </Grid>
      <Grid item {...gridOptions}>
        <ManageStock />
      </Grid>
      <Grid item {...gridOptions}>
        <ManageUsers />
      </Grid>
    </Grid>
  );
};
