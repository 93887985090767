import React, { useMemo, useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import {
  GroupsColumns,
  gscTranslationProperties,
  productGroupsFiltersConfig,
  productTranslationsValidator,
} from "./constants";
import {
  getToArrayOrToEmptyArray,
  toApiUrl,
  toServerImage,
  uploadFile,
} from "../../utils/generic";
import {
  Add,
  ArrowRight,
  Delete,
  Edit,
  Label,
  Reorder,
} from "@mui/icons-material";
import CustomButton from "../ui/Button";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import CustomSwitch from "../ui/Switch";
import FileImport from "../forms/FileImport";
import { Avatar, Box, IconButton } from "@mui/material";
import Message from "../ui/Message";
import { useNavigate } from "react-router-dom";
import useProducts from "../../store/hooks/useProducts";
import SortableList from "../ui/SortableList";
import withSelectionWrapper from "../dataGrid/SelectionWrapper";
import SelectInput from "../forms/SelectInput";
import TranslationForm from "../translations/TranslationForm";

function Groups({ onSelectItems, selectedItems }) {
  const navigate = useNavigate();
  const { websites } = useProducts();
  const [entity, setEntity] = useState(null);
  const [reorderConfig, setReorderConfig] = useState(null);
  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    enabled: {
      defaultValue: true,
      validators: (value) => ValidationService.isBoolean(value),
    },
    image: {
      defaultValue: "",
      validators: (value) => true,
    },
    websites: {
      defaultValue: [],
      validators: (value) => Array.isArray(value),
    },
    translations: {
      defaultValue: [],
      validators: (value) =>
        Array.isArray(value) &&
        (!value.length ||
          value.every((v) =>
            ValidationService.validateBody(v, {
              name: productTranslationsValidator.name,
            })
          )),
    },
  });
  const websiteOptions = useMemo(() => {
    return getToArrayOrToEmptyArray(websites).map((website) => ({
      value: website.id,
      label: website.domain,
    }));
  }, [websites]);
  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({
      id: obj?.id || "",
      name: obj?.name || "",
      enabled: !!obj?.enabled,
      image: obj?.image || "",
      websites: Array.isArray(obj?.websites)
        ? obj.websites.map((w) =>
            ValidationService.isObject(w) ? w.website_id : w
          )
        : [],
      translations: Array.isArray(obj?.translations) ? obj.translations : [],
    });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice("groups");

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const { name, id, description, enabled, image, websites, translations } =
      formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/groups"), {
          name,
          enabled,
          description,
          image,
          websites,
          translations,
        });
      } else {
        res = await axios.put(toApiUrl(`/groups/${id}`), {
          name,
          enabled,
          description,
          image,
          websites,
          translations,
        });
      }
      addNotification(`Group ${id ? "Updated" : "Created"}`, "success");

      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
    } catch (error) {
      addNotification(`Group was not ${id ? "Updated" : "Created"}`, "error");
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(toApiUrl(`/groups/${row.id}`));
      addNotification("Group Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
    } catch (error) {
      addNotification("Group was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const productGroupsFiltersConfigMemoized = useMemo(() => {
    return productGroupsFiltersConfig.concat([
      {
        name: "websites",
        label: "Website",
        grid: { md: 12, xs: 12 },
        value: undefined,
        type: "select",
        options: [{ label: "All", value: "All" }].concat(
          (websites || []).map((x) => ({
            label: x.domain,
            value: x.id,
          }))
        ),
        formatter: (value) => (value === "All" ? undefined : value),
      },
    ]);
  }, [websites]);

  const { form, handleChange } = formConfig;
  return (
    <>
      <TableDataLoader
        filters={{ order_by: "name:asc" }}
        defaultOrderBy="asc"
        defaultSortingColumn="name"
        withColumnSelection
        withFilters
        filtersConfig={productGroupsFiltersConfigMemoized}
        onSelect={onSelectItems}
        selectionId="id"
        endpoint={toApiUrl("/groups")}
        entityName="results"
        identifier="groups"
        columns={GroupsColumns}
        actions={[
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "edit_categories",
            title: "Super Categories",
            icon: <Label />,
            action: (row) =>
              navigate(`/products/groups/${row.id}/supercategories`),
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              {selectedItems?.length > 0 ? (
                <IconButton>
                  <Edit />
                </IconButton>
              ) : null}
              <IconButton
                onClick={() => openDialogForm({ name: "", websites: [] })}
                sx={{ mr: 2 }}
              >
                <Add />
              </IconButton>
              <IconButton
                variant="outlined"
                disabled={!dataSlice?.data?.length}
                onClick={async () => {
                  try {
                    setLoading(true);
                    const { data } = await axios.get(
                      toApiUrl("/static/groups")
                    );
                    setReorderConfig({
                      entity: "groups",
                      items: (data?.results || []).map((x) => ({
                        ...x,
                        primary: <Avatar src={toServerImage(x.image)} />,
                        secondary: x.name,
                        icon: <Reorder />,
                        divider: true,
                      })),
                    });
                  } catch (error) {
                    setReorderConfig(null);
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                <Reorder />
              </IconButton>
            </div>
          );
        }}
      />

      <SimpleDialog
        maxWidth="sm"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Group
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        {!websiteOptions.length ? (
          <>
            <Message message="No websites were created yet. You have to register at least one website in order to start creating groups" />
            <CustomButton
              fullWidth
              endIcon={<ArrowRight />}
              onClick={() => navigate("/websites")}
            >
              Go To Websites
            </CustomButton>
          </>
        ) : (
          <>
            {form.image.value ? (
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={toServerImage(form.image.value)}
              />
            ) : null}
            <FileImport
              label="Upload Group Image"
              onFileUpload={async (files) => {
                setLoading(true);
                const file = await uploadFile(files);
                if (file) {
                  formConfig.handleBaseChange("image", file?.filename);
                }
                setLoading(false);
              }}
            />
            <CustomSwitch
              name="enabled"
              label="Status"
              checked={form.enabled.value}
              onChange={handleChange}
            />
            <Input
              sx={{ my: 2 }}
              {...form.name}
              name="name"
              label="Name"
              value={form.name.value}
              onChange={handleChange}
            />
            <SelectInput
              {...form.websites}
              value={form.websites.value || []}
              label="Websites"
              onChange={handleChange}
              options={websiteOptions}
              autoComplete
              multiple
              sx={{ my: 2 }}
            />
            <Box sx={{ my: 2 }}>
              <TranslationForm
                formConfig={formConfig}
                properties={[
                  gscTranslationProperties.find((x) => x.name === "name"),
                ]}
                formTranslationsKey="translations"
              />
            </Box>
          </>
        )}
      </SimpleDialog>
      <SimpleDialog
        title={
          <Title>
            <Reorder sx={{ mr: 2 }} /> Reorder Groups
          </Title>
        }
        open={!!reorderConfig}
        onCancel={() => setReorderConfig(null)}
        onConfirm={async () => {
          try {
            setLoading(true);
            await axios.put(toApiUrl("/aggregator/reorder"), {
              entity: reorderConfig.entity,
              ids: reorderConfig.items.map(({ id }) => id),
            });
            await getData(dataSlice?.page, dataSlice?.view);
            setReorderConfig(null);
            addNotification("Action completed", "success");
          } catch (error) {
            addNotification("Action failed", "error");
          } finally {
            setLoading(false);
          }
        }}
      >
        {!!reorderConfig ? (
          <SortableList
            items={reorderConfig?.items}
            onReorder={(items) =>
              setReorderConfig((prev) => ({ ...prev, items }))
            }
          />
        ) : null}
      </SimpleDialog>
    </>
  );
}

export default withSelectionWrapper(Groups);
