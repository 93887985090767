import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import ProductAttributes from "../components/products/ProductAttributes";
import { useNavigate, useParams } from "react-router-dom";
import { getProductAttributeCategory } from "../store/actions/products";
import { connect } from "react-redux";
import useProducts from "../store/hooks/useProducts";
import BackdropLoader from "../components/ui/BackdropLoader";
import { removeDataSlice } from "../store/actions/data";

function ProductAttributesPage({
  getProductAttributeCategory,
  removeDataSlice,
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, product_attribute_category } = useProducts();
  useEffect(() => {
    const getCategory = async () => {
      const res = await getProductAttributeCategory(id);
      if (!res) {
        navigate("/404");
      }
    };
    getCategory();
    return () => {
      removeDataSlice({ identifier: "product_attributes" });
    };
  }, [id, navigate, getProductAttributeCategory, removeDataSlice]);

  if (loading) {
    return <BackdropLoader open={loading} />;
  }
  if (!product_attribute_category) {
    return null;
  }
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          links={[
            { title: "Dashboard", href: "/" },
            {
              title: "Products",
              href: "/products",
            },
            {
              title: "Attribute Categories",
              href: "/products/attribute-categories",
            },
            {
              title: product_attribute_category.name,
            },
          ]}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ProductAttributes id={id} />
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(
    connect(null, { getProductAttributeCategory, removeDataSlice })(
      ProductAttributesPage
    ),
    [ROLES.SUPER_ADMIN]
  )
);
