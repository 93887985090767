import { useState } from "react";
import ValidationService from "../../services/ValidationService";
import useForm from "../../store/hooks/useForm";
import { addNotification } from "../../store/actions/notifications";
import axios from "../../apiClient";
import { numericFormFormater, toApiUrl } from "../../utils/generic";
import { productTranslationsValidator } from "./constants";

export default function useProductForm(product) {
  const [loading, setLoading] = useState(false);
  const formConfig = useForm({
    id: {
      defaultValue: product?.id || "",
    },
    name: {
      defaultValue: product?.name || "",
      formatter: (value) =>
        ValidationService.isString(value) ? value.trimStart() : value,
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    sku: {
      defaultValue: product?.sku || "",
      formatter: (value) =>
        ValidationService.isString(value) ? value.trimStart() : value,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    supercategory_id: {
      defaultValue: product?.category?.supercategory_id || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
      onChange: () => ({ category_id: "" }),
    },
    categories: {
      defaultValue:
        Array.isArray(product?.categories) && product?.categories?.length
          ? product?.categories.map((c) =>
              ValidationService.isObject(c) ? c?.category_id : c
            )
          : [],
      validators: (value) => Array.isArray(value),
    },
    brand_id: {
      defaultValue: product?.brand_id || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 2, max: 255 }),
    },
    description: {
      defaultValue: product?.description || "",
      formatter: (value) =>
        ValidationService.isString(value) ? value.trimStart() : value,
      validators: (value) =>
        ValidationService.validateString({ value, min: 0, max: 5000 }),
    },
    enabled: {
      defaultValue: !!product?.enabled,
      validators: (value) => ValidationService.isBoolean(value),
    },
    image: {
      defaultValue: product?.image || "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 1000 }),
    },
    images: {
      defaultValue: Array.isArray(product?.images) ? product?.images : [],
      validators: (value) =>
        ValidationService.isArrayOfType(value, "string") ||
        (Array.isArray(value) && value.length === 0),
    },
    labels: {
      defaultValue: Array.isArray(product?.labels) ? product?.labels : [],
      validators: (value) =>
        ValidationService.isArrayOfType(value, "string") ||
        (Array.isArray(value) && value.length === 0),
    },
    discount: {
      defaultValue: !isNaN(product?.discount)
        ? parseFloat(product?.discount)
        : 0.0,
      formatter: numericFormFormater("float"),
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0, max: 100 }),
      onChange: ({ form }) => ({
        price: form.price.value,
        ...(form.discount.isValid && {
          labels: Array.isArray(form.labels.value)
            ? form.labels.value
                .filter((x) => x.toLowerCase() !== "προσφορές")
                .concat(form.discount.value > 0 ? ["Προσφορές"] : [])
            : form.discount.value > 0
            ? ["Προσφορές"]
            : [],
        }),
      }),
    },
    price: {
      defaultValue: !isNaN(product?.price) ? parseFloat(product?.price) : 0.01,
      formatter: numericFormFormater("float"),
      validators: (value, form) =>
        ValidationService.validateNumber({ value, min: 0.01 }) &&
        (!form.discount.value ||
          (value * (100 - form.discount.value)) / 100 >= 0.01),
      onChange: ({ form }) => ({ discount: form.discount.value }),
    },
    wholesale_price: {
      defaultValue: !isNaN(product?.wholesale_price)
        ? parseFloat(product?.wholesale_price)
        : 0,
      formatter: numericFormFormater("float"),
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0 }),
    },
    tax: {
      defaultValue: !isNaN(product?.tax) ? parseFloat(product?.tax) : 0,
      formatter: numericFormFormater("float"),
      validators: (value) =>
        ValidationService.validateNumber({ value, min: 0, max: 100 }),
    },
    quantity: {
      defaultValue: !isNaN(product?.quantity) ? parseInt(product?.quantity) : 0,
      formatter: numericFormFormater("int"),
      validators: (value) => ValidationService.isNumber(value),
    },
    width: {
      defaultValue: !isNaN(product?.width) ? parseInt(product?.width) : 0,
      formatter: numericFormFormater("float"),
      validators: (value) =>
        ValidationService.isNumber(value) &&
        ValidationService.validateNumber({ value, min: 0 }),
    },
    length: {
      defaultValue: !isNaN(product?.length) ? parseFloat(product?.length) : 0,
      formatter: numericFormFormater("float"),
      validators: (value) =>
        ValidationService.isNumber(value) &&
        ValidationService.validateNumber({ value, min: 0 }),
    },
    height: {
      defaultValue: !isNaN(product?.height) ? parseFloat(product?.height) : 0,
      formatter: numericFormFormater("float"),
      validators: (value) =>
        ValidationService.isNumber(value) &&
        ValidationService.validateNumber({ value, min: 0 }),
    },
    weight: {
      defaultValue: !isNaN(product?.weight) ? parseFloat(product?.weight) : 0,
      formatter: numericFormFormater("float"),
      validators: (value) =>
        ValidationService.isNumber(value) &&
        ValidationService.validateNumber({ value, min: 0 }),
    },
    mpn: {
      defaultValue: product?.mpn,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    ean: {
      defaultValue: product?.ean,
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    keywords: {
      defaultValue: product?.keywords || "",
      validators: (value) =>
        ValidationService.isNullOrUndefinedOrEmpty(value) ||
        ValidationService.validateString({
          value: value?.trim(),
          min: 1,
          max: 1000,
        }),
    },
    meta: {
      defaultValue: product?.meta || {
        minimumCartQuantity: 1,
        cartQuantityPairing: 1,
        maximumCartQuantity: product?.quantity || 100,
      },
      validators: (value) => {
        return (
          value?.minimumCartQuantity <= value?.maximumCartQuantity &&
          (value?.cartQuantityPairing % value.minimumCartQuantity === 0 ||
            value.minimumCartQuantity % value?.cartQuantityPairing === 0) &&
          value?.maximumCartQuantity % value.cartQuantityPairing === 0
        );
      },
    },
    attributes: {
      defaultValue:
        Array.isArray(product?.product_attributes) &&
        product?.product_attributes?.length
          ? product?.product_attributes.map((c) =>
              ValidationService.isObject(c) ? c?.attribute_id : c
            )
          : [],
      validators: (value) => Array.isArray(value),
    },
    translations: {
      defaultValue: Array.isArray(product?.translations)
        ? product.translations
        : [],
      validators: (value) =>
        Array.isArray(value) &&
        (!value.length ||
          value.every((v) =>
            ValidationService.validateBody(v, productTranslationsValidator)
          )),
    },
  });

  const onSubmit = async () => {
    if (!formConfig.isValid) {
      addNotification(`Form is not Valid`);
      return;
    }
    const form = formConfig.getFormValues();
    setLoading(true);
    let res;
    try {
      const handler = form.id ? axios.put : axios.post;
      const url = !form.id
        ? toApiUrl("/products")
        : toApiUrl(`/products/${form.id}`);
      const { id, price, quantity, supercategory_id: _, ...rest } = form;
      res = await handler(url, {
        ...rest,
        price: parseFloat(price).toFixed(2),
        quantity: parseInt(quantity),
        brand_id: rest.brand_id || null,
      });
      addNotification(
        `Product ${form.name || ""} ${id ? "updated" : "created"}`,
        "success"
      );
    } catch (error) {
      addNotification(
        `Product ${form.name || ""} was not ${form.id ? "updated" : "created"}`,
        "error"
      );
    } finally {
      setLoading(false);
      return res;
    }
  };

  return { formConfig, loading, setLoading, onSubmit };
}
