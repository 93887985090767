import React, { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";
import axios from "../../apiClient";
import { toApiUrl, toGreekDate } from "../../utils/generic";

const ActiveUsersBarChart = ({ website_id }) => {
  const [chartData, setChartData] = useState({
    series: [{ name: "Users", data: [] }],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      xaxis: {
        categories: [],
        title: {
          text: " ",
        },
      },
      yaxis: {
        title: {
          text: " ",
        },
      },
      title: {
        text: "Users in the last 30 minutes",
      },
    },
  });

  useEffect(() => {
    let interval;
    const fetchData = () =>
      axios
        .get(toApiUrl(`/tracking/current-users`), { params: { website_id } })

        .then(({ data }) => {
          const categories = data.map((item) => toGreekDate(item.time_bucket));
          const usersData = data.map((item) => item.total_users);

          setChartData((prev) => ({
            ...prev,
            series: [{ name: "Users", data: usersData }],
            options: {
              ...prev.options,
              xaxis: { ...prev.options.xaxis, categories },
            },
          }));
        })
        .catch((err) => console.error("Error fetching data:", err));
    fetchData()
      .then((res) => {
        interval = setInterval(() => fetchData(), 20 * 1000);
      })
      .catch((err) => {});
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [website_id]);

  return (
    <div>
      <ApexCharts
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ActiveUsersBarChart;
