import React, { useMemo, useState } from "react";
import ValidationService from "../../services/ValidationService";
import useProducts from "../../store/hooks/useProducts";
import BackdropLoader from "../ui/BackdropLoader";
import { Avatar, Divider, Grid, IconButton } from "@mui/material";
import {
  Add,
  Cancel,
  Delete,
  Edit,
  Save,
  ShoppingBag,
} from "@mui/icons-material";
import ListView from "../ui/ListView";
import Title from "../ui/Title";
import SelectInput from "../forms/SelectInput";
import Input from "../forms/Input";
import CustomSwitch from "../ui/Switch";

export default function XMLProviderConfig({ formConfig, providerFormConfig }) {
  const [on, setOn] = useState(false);

  const { product_categories, product_brands, loading } = useProducts();
  const providers = useMemo(() => {
    try {
      return ValidationService.isObject(formConfig.form.meta.value.xmlProviders)
        ? Object.entries(formConfig.form.meta?.value?.xmlProviders).reduce(
            (acc, [provider, config], idx) => {
              acc.push({
                primary: provider,
                icon: (
                  <Avatar>
                    <ShoppingBag />
                  </Avatar>
                ),
                renderActions: () => (
                  <>
                    <IconButton
                      onClick={() => {
                        providerFormConfig.resetForm({
                          id: idx + 1,
                          name: provider,
                          from_quantity: isNaN(config?.from_quantity)
                            ? 0
                            : parseInt(config?.from_quantity),
                          categories: Array.isArray(config?.categories)
                            ? config.categories
                            : [],
                          brands: Array.isArray(config?.brands)
                            ? config.brands
                            : [],
                          requiresMPN: !!config?.requiresMPN,
                          requiresEAN: !!config?.requiresEAN,
                          disableShipping: !!config?.disableShipping,
                          useProductIdInLink: !!config?.useProductIdInLink,
                        });
                        setOn(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        formConfig.handleBaseChange("meta", {
                          ...formConfig.form.meta.value,
                          xmlProviders: Object.entries(
                            formConfig.form.meta.value.xmlProviders
                          ).reduce((acc, [key, value], i) => {
                            if (i === idx) {
                              return acc;
                            }

                            acc[key] = value;
                            return acc;
                          }, {}),
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </>
                ),
              });
              return acc;
            },
            []
          )
        : [];
    } catch (error) {
      return [];
    }
  }, [formConfig, providerFormConfig]);

  const productCategoryOptions = useMemo(() => {
    if (!product_categories?.length) {
      return [];
    }

    return product_categories.map((product_category) => ({
      label: product_category.name,
      value: product_category.id,
    }));
  }, [product_categories]);

  const productBrandsOptions = useMemo(() => {
    if (!product_brands?.length) {
      return [];
    }

    return [{ label: "None", value: null }].concat(
      product_brands.map((x) => ({
        label: x.name,
        value: x.id,
      }))
    );
  }, [product_brands]);

  return (
    <>
      <BackdropLoader open={loading} />
      {!on ? (
        <ListView
          title={
            <Title sx={{ my: 2 }} divider>
              <Title>Merchant XML Configuration</Title>
              <IconButton
                onClick={() => {
                  providerFormConfig.resetForm();
                  setOn(true);
                }}
              >
                <Add />
              </IconButton>
            </Title>
          }
          items={providers}
        />
      ) : (
        <Grid
          container
          spacing={2}
          sx={{ border: "3px dotted #259", my: 2, p: 1 }}
        >
          <Grid item md={12} xs={12}>
            <Input
              {...providerFormConfig.form.name}
              name="name"
              type="text"
              value={providerFormConfig.form.name?.value || ""}
              label="Merchant Name"
              onChange={providerFormConfig.handleChange}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <SelectInput
              {...providerFormConfig.form.categories}
              disabled={!productCategoryOptions?.length}
              name="categories"
              label="Categories"
              onChange={providerFormConfig.handleChange}
              options={productCategoryOptions}
              value={providerFormConfig.form.categories.value || []}
              autoComplete
              multiple
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SelectInput
              {...providerFormConfig.form.brands}
              disabled={!productBrandsOptions?.length}
              name="brands"
              label="Brands"
              onChange={providerFormConfig.handleChange}
              options={productBrandsOptions}
              value={providerFormConfig.form.brands.value || []}
              autoComplete
              multiple
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...providerFormConfig.form.from_quantity}
              type="number"
              name="from_quantity"
              value={providerFormConfig.form.from_quantity.value}
              label="Minimum Quantity"
              onChange={providerFormConfig.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSwitch
              name="requiresEAN"
              label="Include EAN / Barcode"
              checked={providerFormConfig.form.requiresEAN.value}
              type="checkbox"
              onChange={providerFormConfig.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSwitch
              name="requiresMPN"
              label="Include MPN / ISBN"
              checked={providerFormConfig.form.requiresMPN.value}
              type="checkbox"
              onChange={providerFormConfig.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSwitch
              name="disableShipping"
              label="Disable Shipping Cost"
              checked={providerFormConfig.form.disableShipping.value}
              type="checkbox"
              onChange={providerFormConfig.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSwitch
              name="useProductIdInLink"
              label={
                providerFormConfig.form.useProductIdInLink.value
                  ? "Product Link will include ID"
                  : "Product Link will use <slug> and fallback to <id>"
              }
              checked={providerFormConfig.form.useProductIdInLink.value}
              type="checkbox"
              onChange={providerFormConfig.handleChange}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                providerFormConfig.resetForm();
                setOn(false);
              }}
            >
              <Cancel />
            </IconButton>
            <IconButton
              disabled={!providerFormConfig.isValid}
              onClick={() => {
                const { id, name, ...rest } =
                  providerFormConfig.getFormValues();
                const provider = providers.find((x, idx) => idx + 1 === id);
                if (provider) {
                  const { [provider.primary]: _, ...otherProviders } =
                    formConfig.form.meta.value?.xmlProviders || {};
                  formConfig.handleBaseChange("meta", {
                    ...formConfig.form.meta.value,
                    xmlProviders: {
                      ...otherProviders,
                      [name]: rest,
                    },
                  });
                } else {
                  formConfig.handleBaseChange("meta", {
                    ...formConfig.form.meta.value,
                    xmlProviders: {
                      ...formConfig.form.meta.value?.xmlProviders,
                      [name]: rest,
                    },
                  });
                }

                providerFormConfig.resetForm();
                setOn(false);
              }}
            >
              <Save />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}
