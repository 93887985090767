import { useCallback, useEffect, useState } from "react";
import ValidationService from "../../services/ValidationService";

const getIsFormTouched = (form) =>
  Object.values(form).some((val) => val.isTouched);

const getIsFormValid = (form) => {
  return Object.values(form).every((val) => val?.isValid);
};

const validateField = (validator) => (value, form, setForm) =>
  validator(value, form, setForm);

const getValidatedFields = (form) => {
  return Object.entries(form).reduce((acc, [fieldName, fieldConfig]) => {
    return {
      ...acc,
      [fieldName]: {
        ...fieldConfig,
        isValid: (fieldConfig.validators || []).every((validator) =>
          validator(fieldConfig.value, form)
        ),
      },
    };
  }, {});
};

const initiForm = (fields, onValidate) => {
  const form = Object.entries(fields).reduce(
    (
      acc,
      [
        key,
        {
          value,
          defaultValue,
          validators,
          extractUniqueValuesFn,
          type,
          isRequired,
          label,
          error,
          errors,
          accept,
          isMultiple,
          isUniqueOnMultiple,
          isFileUpload,
          uiOptions,
          name,
          passwordVisibility = false,
          placeholder,
          placeholderDisabled,
          placeholderValue,
          onChange,
          sideEffect,
          formatter,
        },
      ]
    ) => {
      let fixedValidators = [];
      if (validators) {
        fixedValidators = Array.isArray(validators) ? validators : [validators];
      }

      const formatedValue = formatter
        ? formatter(defaultValue ?? value)
        : defaultValue ?? value;

      return {
        ...acc,
        [key]: {
          defaultValue: formatedValue,
          validators: fixedValidators,
          name: name ?? key,
          isRequired,
          type,
          label,
          error,
          errors,
          accept,
          isFileUpload,
          isMultiple,
          isUniqueOnMultiple,
          extractUniqueValuesFn,
          uiOptions,
          isValid: false,
          isTouched: false,
          ...(type === "password" && {
            passwordVisibility,
            togglePasswordVisibility: (value) => !value,
          }),
          placeholder,
          placeholderDisabled,
          placeholderValue,
          value: formatedValue,
          onChange,
          sideEffect,
          formatter,
        },
      };
    },
    {}
  );
  const validatedForm = getValidatedFields(form);

  const isTouched = getIsFormTouched(form);

  const isValid = getIsFormValid(validatedForm);
  if (onValidate) {
    onValidate({ isValid, isTouched, form });
  }
  return {
    form: validatedForm,
    isTouched,
    isValid,
  };
};

export default function useForm(fields, onValidate) {
  const [form, setForm] = useState(() => initiForm(fields, onValidate));

  const getSideEffectValue = useCallback(
    ({ onChange, fieldValue, form, fieldName }) => {
      if (!onChange) {
        return {};
      }
      const newValues = onChange({ fieldValue, form, fieldName });
      if (!ValidationService.isObject(newValues)) {
        return {};
      }

      return Object.entries(newValues).reduce((acc, [key, value]) => {
        if (!fields?.[key]) {
          return acc;
        }
        const { validators = [] } = form[key];
        return {
          ...acc,
          [key]: {
            ...form?.[key],
            value,
            isTouched: true,
            isValid:
              !validators?.length ||
              validators.every((validator) =>
                validateField(validator)(value, form, setForm)
              ),
          },
        };
      }, {});
    },
    [fields]
  );

  const handleChange = useCallback(
    (e) => {
      const { value: eValue, type, name, checked } = e.target;
      if (!form.form[name]) return;

      let fieldValue;
      switch (type) {
        case "checkbox":
          fieldValue = checked;
          break;
        default:
          fieldValue = eValue;
      }

      const {
        isMultiple,
        isUniqueOnMultiple,
        extractUniqueValuesFn,
        value,
        validators,
        formatter,
        onChange,
        sideEffect,
      } = form.form?.[name] || {};

      if (formatter) {
        fieldValue = formatter(fieldValue, form.form);
      }

      if (isMultiple) {
        let newValue = Array.isArray(value)
          ? [...value, fieldValue]
          : [fieldValue];
        if (isUniqueOnMultiple) {
          if (extractUniqueValuesFn) {
            fieldValue = newValue.filter((val) =>
              extractUniqueValuesFn(val, newValue)
            );
          } else {
            fieldValue = [...new Set(newValue)];
          }
        }

        fieldValue = newValue;
      }

      setForm((prev) => {
        const newForm = {
          ...prev.form,
          [name]: {
            ...prev.form?.[name],
            value: fieldValue,
            isTouched: true,
            isValid: validators.every((validator) =>
              validateField(validator)(fieldValue, prev.form, setForm)
            ),
          },
        };

        Object.assign(
          newForm,
          getSideEffectValue({
            onChange,
            fieldValue,
            form: newForm,
            fieldName: name,
          })
        );
        getSideEffectValue({
          onChange: sideEffect,
          fieldValue,
          form: newForm,
          fieldName: name,
        });

        const isValid = getIsFormValid(newForm);
        if (onValidate) {
          onValidate({ isValid, form: newForm, isTouched: true });
        }
        return {
          form: newForm,
          isTouched: true,
          isValid,
        };
      });
    },
    [form.form, getSideEffectValue, onValidate]
  );

  const handleFileUpload = (e) => {
    // const { file: eValue, files, type, name, checked } = e.target;
    // if (!form.form[name]) return;
  };

  const handleBaseChange = useCallback(
    (name, fieldValue) => {
      if (!form.form[name]) return;
      const { validators, formatter, onChange, sideEffect } =
        form.form?.[name] || {};

      setForm((prev) => {
        if (formatter) {
          fieldValue = formatter(fieldValue, prev.form);
        }
        const newForm = {
          ...prev.form,
          [name]: {
            ...prev.form?.[name],
            value: fieldValue,
            isTouched: true,
            isValid: validators
              .map(validateField)
              .every((validator) => validator(fieldValue, prev.form, setForm)),
          },
        };
        Object.assign(
          newForm,
          getSideEffectValue({
            onChange,
            fieldValue,
            form: newForm,
            fieldName: name,
          })
        );

        getSideEffectValue({
          onChange: sideEffect,
          fieldValue,
          form: newForm,
          fieldName: name,
        });
        const isValid = getIsFormValid(newForm);
        if (onValidate) {
          onValidate({ isValid, form: newForm, isTouched: true });
        }
        return {
          form: newForm,
          isTouched: true,
          isValid,
        };
      });
    },
    [form.form, getSideEffectValue, onValidate]
  );

  const handleMultipleChange = useCallback(
    (values = {}) => {
      setForm((prev) => {
        const newForm = {
          ...prev.form,
          ...Object.entries(values).reduce((acc, [name, fieldValue]) => {
            if (!ValidationService.isObject(prev.form?.[name])) {
              return acc;
            }
            const { validators, formatter } = prev?.form?.[name] || {};
            if (formatter) {
              fieldValue = formatter(fieldValue, prev.form);
            }
            return {
              ...acc,
              [name]: {
                ...prev.form?.[name],
                value: fieldValue,
                isTouched: true,
                isValid: validators
                  .map(validateField)
                  .every((validator) =>
                    validator(fieldValue, prev.form, setForm)
                  ),
              },
            };
          }, {}),
        };
        const isValid = getIsFormValid(newForm);
        if (onValidate) {
          onValidate({ isValid, form: newForm, isTouched: true });
        }
        return {
          form: newForm,
          isTouched: true,
          isValid,
        };
      });
    },
    [onValidate]
  );

  const resetForm = (newFieldsObj = undefined) => {
    if (!newFieldsObj) {
      setForm(() => initiForm(fields));
    } else {
      const newForm = Object.entries(fields).reduce(
        (acc, [fieldName, config]) => {
          return {
            ...acc,
            [fieldName]: {
              ...config,
              value: newFieldsObj?.[fieldName] ?? config?.value,
              defaultValue: newFieldsObj?.[fieldName] ?? config?.value,
            },
          };
        },
        {}
      );
      setForm((prev) => initiForm(newForm));
    }
  };

  const resetFormByKeys = (keys = []) => {
    keys.forEach((k) => handleBaseChange(k, form?.[k]?.defaultValue));
  };

  const setFormValidity = (isValid) => {
    setForm((prev) => {
      return {
        ...prev,
        form: Object.entries(prev.form || {}).reduce((acc, [name, value]) => {
          acc[name] = {
            ...value,
            isValid,
          };
          return acc;
        }, {}),
        isValid,
      };
    });
  };

  const getFormValues = () =>
    Object.entries(form.form).reduce((acc, [name, entry]) => {
      return {
        ...acc,
        [name]: entry.value,
      };
    }, {});

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
    }));
  }, []);

  return {
    ...form,
    handleChange,
    handleBaseChange,
    handleFileUpload,
    resetForm,
    setForm,
    getFormValues,
    setFormValidity,
    resetFormByKeys,
    handleMultipleChange,
  };
}
