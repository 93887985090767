import React, { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { localeOptions, locales } from "./constants";
import SelectInput from "../forms/SelectInput";
import TranslationFormItem from "./TranslationFormItem";

export default function TranslationForm({
  properties = [],
  formConfig,
  formTranslationsKey = "translations",
}) {
  const [selected, setSelected] = useState(null);
  const selectedLocales = useMemo(() => {
    const value = formConfig.form[formTranslationsKey]?.value;

    if (!Array.isArray(value)) {
      return [];
    }
    return value.filter((x) => !!locales[x.locale]).map((x) => x.locale);
  }, [formConfig.form[formTranslationsKey].value, formTranslationsKey]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <SelectInput
          isValid
          isTouched
          name="locales"
          label="Languages"
          onChange={(e) => {
            const newValue = (e.target.value || []).reduce((acc, locale) => {
              const translation = (
                formConfig.form.translations.value || []
              ).find((x) => x.locale === locale);
              if (!translation) {
                acc.push(
                  properties.reduce(
                    (a, prop) => {
                      a[prop.name] = "";
                      return a;
                    },
                    { locale }
                  )
                );
              } else {
                acc.push(translation);
              }
              return acc;
            }, []);

            formConfig.handleBaseChange(formTranslationsKey, newValue);
          }}
          options={localeOptions}
          value={selectedLocales}
          autoComplete
          multiple
        />
      </Grid>
      {(formConfig.form[formTranslationsKey]?.value || []).map(
        (translationConfig) => (
          <Grid item md={12} xs={12} key={translationConfig.locale}>
            <TranslationFormItem
              onSelect={(x) => setSelected((prev) => (prev === x ? null : x))}
              selected={selected}
              properties={properties}
              formConfig={formConfig}
              formTranslationsKey={formTranslationsKey}
              translation={translationConfig}
            />
          </Grid>
        )
      )}
    </Grid>
  );
}
