import React, { useEffect, useMemo, useState } from "react";
import withAuth from "../hoc/withAuth";
import { Grid, Paper } from "@mui/material";
import Title from "../components/ui/Title";
import { ShoppingCart } from "@mui/icons-material";
import { ManagementGrid } from "../components/administration/ManagementActions";
import { connect, useDispatch } from "react-redux";
import { getDashboardaggregatedData } from "../store/actions/aggregate";
import DashboardCards from "../components/administration/DashboardCards";
import { NotificationTypes } from "../components/notifications/constants";
import { getLatestOrders } from "../store/actions/orders";
import RestTable from "../components/tables/rest/RestTable";
import { OrdersColumns } from "../components/orders/constants";
import { useNavigate } from "react-router-dom";
import Message from "../components/ui/Message";
// import TransactionsTimeSeries from "../components/payments/TransactionsTimeSeries";
// import useAggregator from "../store/hooks/useAggregator";
import PopularWebsiteProducts from "../components/websites/PopularWebsiteProducts";
// import AnalyticsCard from "../components/ui/AnalyticsCard";
// import ActiveUsersBarChart from "../components/websites/ActiveUsersBarChart";

function DashboardPage({ socket, getLatestOrders }) {
  const dispatch = useDispatch();
  // const { dashboard } = useAggregator();
  const [latestOrders, setLatestOrders] = useState([]);
  const navigate = useNavigate();

  const popularFilters = useMemo(() => ({ order_by: "popularity:desc" }), []);
  const mostViewedFilters = useMemo(
    () => ({ order_by: "most_viewed:desc" }),
    []
  );

  useEffect(() => {
    getLatestOrders(50, {
      order_statuses: ["created", "paid", "completed"],
    }).then((res) => {
      if (res?.results?.length) {
        setLatestOrders((prev) => res?.results);
      }
    });
    dispatch(getDashboardaggregatedData());
  }, [dispatch, getLatestOrders]);

  useEffect(() => {
    const listener = async ({ data }) => {
      dispatch(getDashboardaggregatedData());
      const res = await getLatestOrders(50, {
        order_statuses: ["created", "paid", "completed"],
      });
      if (res) {
        setLatestOrders((prev) =>
          Array.isArray(res?.results) ? res?.results : prev
        );
      }
    };
    socket.on(NotificationTypes.sales_notification, listener);

    return () => {
      socket.removeListener(NotificationTypes.sales_notification, listener);
    };
  }, [socket, getLatestOrders, dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <DashboardCards />
      </Grid>

      <Grid item md={5} xs={12}>
        <PopularWebsiteProducts
          gridOptions={{ md: 6, xs: 12 }}
          filters={popularFilters}
          title="Most Popular Products"
        />
        <PopularWebsiteProducts
          gridOptions={{ md: 6, xs: 12 }}
          filters={mostViewedFilters}
          title="Most Viewed Products"
        />
      </Grid>
      <Grid item md={7} xs={12}>
        {/* <Title divider sx={{ my: 2 }}>
          <ShoppingCart sx={{ mr: 2 }} /> Orders History
        </Title>
        <TransactionsTimeSeries
          data={dashboard?.ordersChartData || []}
          labelFN={(val) =>
            new Date(val?.created_at)?.toLocaleDateString("el-GR")
          }
          valueFN={(val) => val?.total}
          tooltipTitle={"Total Orders"}
        /> */}
        <Title divider sx={{ my: 2 }}>
          <ShoppingCart sx={{ mr: 2 }} /> Latest Orders (
          {latestOrders?.length || 0})
        </Title>
        <Paper sx={{ p: 1, mt: 5 }} elevation={0}>
          {latestOrders?.length ? (
            <RestTable
              onRowClick={(row) => navigate(`/orders/${row.id}`)}
              columns={OrdersColumns.filter(
                (c, idx) => idx > 2 && idx < 8 && c.name !== "actions"
              )}
              rows={latestOrders}
              count={-1}
              disableSelection
            />
          ) : (
            <Message message="Nothing at the moment" />
          )}
        </Paper>
        <ManagementGrid />
      </Grid>
    </Grid>
  );
}

export default withAuth(connect(null, { getLatestOrders })(DashboardPage));
