import React, { useEffect, useMemo, useState } from "react";
import { ROLES } from "../data/roles";
import withRoles from "../hoc/withRoles";
import withAuth from "../hoc/withAuth";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../apiClient";
import { toApiUrl } from "../utils/generic";
import Message from "../components/ui/Message";
import BackdropLoader from "../components/ui/BackdropLoader";
import { Grid } from "@mui/material";
import AnalyticsCard from "../components/ui/AnalyticsCard";
import { LanguageOutlined } from "@mui/icons-material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import PopularWebsiteProducts from "../components/websites/PopularWebsiteProducts";
import ActiveUsersBarChart from "../components/websites/ActiveUsersBarChart";

function AnalyticsPage() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [website, setWebsite] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWebsite = async () => {
      try {
        const { data } = await axios.get(toApiUrl(`/websites/${id}`));
        if (!data?.meta?.trackingEnabled) {
          navigate("/404");
        }
        setWebsite(data);
      } catch (error) {
        setWebsite(null);
        navigate("/404");
      }
    };

    setLoading(true);

    fetchWebsite()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => setLoading(false));

    return () => {};
  }, [id, navigate]);

  const popularityFilters = useMemo(() => {
    return {
      website: website?.id,
      order_by: "popularity:desc",
      view: 12,
    };
  }, [website?.id]);

  const mostViewedFilters = useMemo(() => {
    return {
      website: website?.id,
      order_by: "most_viewed:desc",
      view: 12,
    };
  }, [website?.id]);

  if (!website) {
    return null;
  }

  return (
    <>
      <BackdropLoader open={loading} />
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <AppWidgetSummary
            links={[
              { title: "Dashboard", href: "/" },
              { title: "Websites", href: "/websites" },
              { title: website?.domain || "" },
              { title: "Analytics" },
            ]}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <AnalyticsCard
            icon={<LanguageOutlined sx={{ mr: 2 }} />}
            title={website.domain}
          >
            <ActiveUsersBarChart website_id={id} />
          </AnalyticsCard>
        </Grid>

        {/* <Grid item lg={4} md={12} xs={12}>
          <AnalyticsCard
            icon={<FlagCircle />}
            count={byCountry?.length}
            title="Countries"
          />
        </Grid> */}

        {/* <Grid item flexGrow={1} justifySelf={"flex-end"}>
          <Tooltip title="Clear Cache">
            <IconButton onClick={handleClearCache}>
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </Grid> */}
        {/* <Grid item md={12} xs={12}>
          <Paper sx={{ p: 2 }}>
            <LeafletMap data={events} />
          </Paper>
        </Grid> */}
        <Grid item md={12} xs={12}>
          <PopularWebsiteProducts
            title={`Most Popular Products for ${website?.domain || "-"}`}
            filters={popularityFilters}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <PopularWebsiteProducts
            title={`Most Viewed Products for ${website?.domain || "-"}`}
            filters={mostViewedFilters}
          />
        </Grid>
        {/* 
        <Grid item md={6} xs={12}>
          <WebsiteTimeSeries
            id={website.id}
            title={"Traffic Every 5 seconds"}
          />
        </Grid> */}
      </Grid>
    </>
  );
}

export default withAuth(
  withRoles(AnalyticsPage, [ROLES.SUPER_ADMIN, ROLES.ADMIN])
);
