import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";

import ProductSuperCategories from "../components/products/ProductSuperCategories";
import { getGroups, getWebsites } from "../store/actions/products";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useProducts from "../store/hooks/useProducts";
import { useCallback } from "react";

function ProductSuperCategoriesPage({
  identifier = "product_super_categories",
}) {
  const dispatch = useDispatch();
  const { group_id } = useParams();
  const { groups } = useProducts();
  useEffect(() => {
    dispatch(getWebsites());
    dispatch(getGroups());
  }, [dispatch]);

  const getLinks = useCallback(() => {
    if (!group_id) {
      return [
        { title: "Dashboard", href: "/" },
        {
          title: "Products",
          href: "/products",
        },
        { title: "Super Categories" },
      ];
    }

    const group = (groups || []).find((g) => g.id === group_id);

    return group
      ? [
          { title: "Dashboard", href: "/" },
          {
            title: "Products",
            href: "/products",
          },
          { title: "Groups", href: `/products/groups` },
          { title: group.name },
          { title: "Super Categories" },
        ]
      : [
          { title: "Dashboard", href: "/" },
          {
            title: "Products",
            href: "/products",
          },
          { title: "Super Categories" },
        ];
  }, [group_id, groups]);

  return (
    <Grid container spacing={2}>
      <AppWidgetSummary links={getLinks()} />

      <Grid item md={12} xs={12}>
        <ProductSuperCategories key={identifier} identifier={identifier} />
      </Grid>
    </Grid>
  );
}

export default withAuth(
  withRoles(ProductSuperCategoriesPage, [ROLES.SUPER_ADMIN])
);
