import React, { useState } from "react";
import TableDataLoader from "../dataGrid/TableDataLoader";
import {
  gscTranslationProperties,
  ProductAttributeCategoriesColumns,
  productTranslationsValidator,
} from "./constants";
import { toApiUrl } from "../../utils/generic";
import { Add, Delete, Edit, Label } from "@mui/icons-material";
import useForm from "../../store/hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Title from "../ui/Title";
import Input from "../forms/Input";
import useDataSlice from "../dataGrid/useDataSlice";
import axios from "../../apiClient";
import { addNotification } from "../../store/actions/notifications";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import TranslationForm from "../translations/TranslationForm";

export default function ProductAttributeCategories() {
  const [entity, setEntity] = useState(null);
  const navigate = useNavigate();
  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({ value, min: 1, max: 255 }),
    },
    translations: {
      defaultValue: [],
      validators: (value) =>
        Array.isArray(value) &&
        (!value.length ||
          value.every((v) =>
            ValidationService.validateBody(v, {
              name: productTranslationsValidator.name,
            })
          )),
    },
  });
  const openDialogForm = (obj) => {
    setEntity(obj);
    formConfig.resetForm({
      id: obj?.id || "",
      name: obj?.name || "",
      translations: Array.isArray(obj?.translations) ? obj.translations : [],
    });
  };

  const closeDialog = () => {
    formConfig.resetForm();
    setEntity(null);
  };

  const [dataSlice, getData, setLoading] = useDataSlice(
    "product_attribute_categories"
  );

  const onViewChildren = (row) => {
    navigate(`/products/attribute-categories/${row.id}`);
  };

  const onSubmit = async () => {
    setLoading(true);
    let res;
    const { name, id, translations } = formConfig.getFormValues();
    try {
      if (!id) {
        res = await axios.post(toApiUrl("/product-attribute-categories"), {
          name,
          translations,
        });
      } else {
        res = await axios.put(toApiUrl(`/product-attribute-categories/${id}`), {
          name,
          translations,
        });
      }
      addNotification(
        `Product Attribute Category ${id ? "Updated" : "Created"}`,
        "success"
      );

      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification(
        `Product Attribute Category was not ${id ? "Updated" : "Created"}`,
        "error"
      );
    } finally {
      setLoading(false);
      if (res) {
        closeDialog();
      }
      return !!res;
    }
  };

  const onDelete = async (row) => {
    let res;
    try {
      setLoading(true);
      res = await axios.delete(
        toApiUrl(`/product-attribute-categories/${row.id}`)
      );
      addNotification("Product Attribute Category Deleted", "success");
      await getData(dataSlice?.page, dataSlice?.view);
    } catch (error) {
      addNotification("Product Attribute Category was not Deleted", "error");
    } finally {
      setLoading(false);
      return !!res;
    }
  };

  const { form, handleChange } = formConfig;
  return (
    <>
      <TableDataLoader
        endpoint={toApiUrl("/product-attribute-categories")}
        entityName="results"
        identifier="product_attribute_categories"
        columns={ProductAttributeCategoriesColumns}
        actions={[
          {
            name: "view",
            title: "Attributes",
            icon: <Label />,
            action: onViewChildren,
          },
          {
            name: "edit",
            title: "Edit",
            icon: <Edit />,
            action: openDialogForm,
          },
          {
            name: "delete",
            title: "Delete",
            icon: <Delete />,
            action: onDelete,
          },
        ]}
        renderToolbarChildren={() => {
          return (
            <IconButton onClick={() => openDialogForm({ name: "" })}>
              <Add />
            </IconButton>
          );
        }}
      />
      <SimpleDialog
        maxWidth="sm"
        onCancel={closeDialog}
        onConfirm={onSubmit}
        title={
          <Title>
            <Edit /> {form.id.value ? "Edit" : "Add New"} Product Attribute
            Category
          </Title>
        }
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || dataSlice?.loading
        }
        open={!!entity}
      >
        <Input
          {...form.name}
          name="name"
          label="Category"
          value={form.name.value}
          onChange={handleChange}
        />
        <Box sx={{ my: 2 }}>
          <TranslationForm
            formConfig={formConfig}
            properties={[
              gscTranslationProperties.find((x) => x.name === "name"),
            ]}
            formTranslationsKey="translations"
          />
        </Box>
      </SimpleDialog>
    </>
  );
}
