import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../components/ui/AppWidgetSummary";
import { ShoppingBag } from "@mui/icons-material";
import Products from "../components/products/Products";
import withRoles from "../hoc/withRoles";
import { ROLES } from "../data/roles";
import {
  getProductBrands,
  getProductSuperCategories,
} from "../store/actions/products";
import { useDispatch } from "react-redux";
import useProducts from "../store/hooks/useProducts";
import BackdropLoader from "../components/ui/BackdropLoader";

function ProductsPage({ url }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductSuperCategories());
    dispatch(getProductBrands());
  }, [dispatch]);

  const { product_supercategories, loading } = useProducts();

  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      <Grid item md={12} xs={12}>
        <AppWidgetSummary
          links={[{ title: "Dashboard", href: "/" }, { title: "Products" }]}
          title={"Products"}
          icon={<ShoppingBag />}
        />
      </Grid>
      {/* <Grid item md={12} xs={12}>
        <GoBackButton to="/" />
      </Grid> */}
      {!!product_supercategories?.length ? (
        <Grid item md={12} xs={12}>
          <Products url={url} />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default withAuth(
  withRoles(ProductsPage, [], ({ location, role }) => {
    if (location?.pathname === "/products") {
      return role?.name === ROLES.SUPER_ADMIN;
    }

    return true;
  })
);
