import React from "react";
import { Route, Routes } from "react-router-dom";

import AuthWrapper from "./layouts/AuthWrapper";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import ProfilePage from "./pages/ProfilePage";
import RequestPasswordResetPage from "./pages/RequestPasswordResetPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import DashboardPage from "./pages/DashboardPage";
import AdministrationPage from "./pages/AdministrationPage";
import ProductAttributeCategoriesPage from "./pages/ProductAttributeCategoriesPage";
import ProductAttributesPage from "./pages/ProductAttributesPage";
import ProductSuperCategoriesPage from "./pages/ProductSuperCategoriesPage";
import ProductCategoriesPage from "./pages/ProductCategoriesPage";
import OrderStatusesPage from "./pages/OrderStatusesPage";
import PaymentMethodsPage from "./pages/PaymentMethodsPage";
import ProductsPage from "./pages/ProductsPage";
import ProductPage from "./pages/ProductPage";
import MonitoringPage from "./pages/MonitoringPage";
import WebsitesPage from "./pages/WebsitesPage";
import EditUserPage from "./pages/EditUserPage";
import NewsletterPage from "./pages/NewsletterPage";
import ProductBrandsPage from "./pages/ProductBrandsPage";
import WebsiteSectionsPage from "./pages/WebsiteSectionsPage";
import OrdersPage from "./pages/OrdersPage";
import InventoryPage from "./pages/InventoryPage";
import OrderPage from "./pages/OrderPage";
import ShippingCompaniesPage from "./pages/ShippingCompaniesPage";
import SuppliersPage from "./pages/SuppliersPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import ManageSupplierPage from "./pages/ManageSupplierPage";
import GroupsPage from "./pages/GroupsPage";
import NotificationPage from "./pages/NotificationPage";
// import BoardsPage from "./pages/BoardsPage";
import BoardPage from "./pages/BoardPage";
import CouponsPage from "./pages/CouponsPage";
import CachePage from "./pages/CachePage";
import BlogPostsPage from "./pages/BlogPostsPage";
import DatabasePage from "./pages/DatabasePage";

export default function Router() {
  return (
    <Routes>
      <Route index path="/" element={<DashboardPage />} />
      <Route path="/websites" element={<WebsitesPage />} />
      <Route path="/notifications" element={<NotificationPage />} />
      <Route path="/websites/:id/sections" element={<WebsiteSectionsPage />} />
      <Route path="/websites/:id/analytics" element={<AnalyticsPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      {/* <Route path="/boards" element={<BoardsPage />} /> */}
      <Route path="/boards/:id" element={<BoardPage />} />
      <Route
        path="/products/attribute-categories"
        element={<ProductAttributeCategoriesPage />}
      />
      <Route
        path="/products/attribute-categories/:id"
        element={<ProductAttributesPage />}
      />
      <Route path="/products/groups" element={<GroupsPage />} />
      <Route
        path="/products/groups/:group_id/supercategories"
        element={
          <ProductSuperCategoriesPage identifier="product_supercategories_group" />
        }
      />
      <Route
        path="/products/supercategories"
        element={<ProductSuperCategoriesPage />}
      />
      <Route
        path="/products/supercategories/:supercategory_id/categories"
        element={
          <ProductCategoriesPage
            identifier={"product_categories_by_supercategory"}
          />
        }
      />
      <Route path="/products/brands" element={<ProductBrandsPage />} />
      <Route path="/products/categories" element={<ProductCategoriesPage />} />
      <Route
        path="/products/account"
        element={<ProductsPage url="/products/account" />}
      />
      <Route path="/products" element={<ProductsPage url="/products" />} />
      <Route path="/inventory" element={<InventoryPage url="/inventory" />} />
      <Route path="/products/:id" element={<ProductPage />} />
      <Route path="/monitoring" element={<MonitoringPage />} />
      <Route path="/orders/statuses" element={<OrderStatusesPage />} />
      <Route path="/orders/coupons" element={<CouponsPage />} />
      <Route
        path="/orders/shipping-companies"
        element={<ShippingCompaniesPage />}
      />
      <Route path="/orders/payment-methods" element={<PaymentMethodsPage />} />
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/orders/:id" element={<OrderPage />} />
      <Route path="/blogs" element={<BlogPostsPage />} />
      <Route
        path="/users"
        element={
          <AdministrationPage title="Platform Users" identifier="users" />
        }
      />
      <Route
        path="/users/account"
        element={
          <AdministrationPage
            isAccountBased
            title="Account Users"
            identifier="account_users"
          />
        }
      />
      <Route path="/users/edit/:id" element={<EditUserPage />} />
      <Route path="/newsletter" element={<NewsletterPage />} />
      <Route path="/cache" element={<CachePage />} />
      <Route path="/suppliers" element={<SuppliersPage />} />
      <Route path="/suppliers/:id" element={<ManageSupplierPage />} />
      <Route path="/database" element={<DatabasePage />} />
      <Route
        path="/request-reset-password"
        element={
          <AuthWrapper>
            <RequestPasswordResetPage />
          </AuthWrapper>
        }
      />
      <Route
        path="/reset-password"
        element={
          <AuthWrapper>
            <ResetPasswordPage />
          </AuthWrapper>
        }
      />
      <Route
        path="/login"
        element={
          <AuthWrapper>
            <LoginPage />
          </AuthWrapper>
        }
      />

      {/* <Route
        path="/register"
        element={
          <AuthWrapper>
            <RegisterPage />
          </AuthWrapper>
        }
      /> */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
