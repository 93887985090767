import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import React from "react";
import { locales } from "./constants";
import { Delete, ExpandMore, LanguageOutlined } from "@mui/icons-material";
import Input from "../forms/Input";
import Title from "../ui/Title";
import MDEditor from "@uiw/react-md-editor";

export default function TranslationFormItem({
  formConfig,
  properties = [],
  translation,
  formTranslationsKey = "translations",
  onSelect,
  selected,
}) {
  if (
    !translation ||
    !Array.isArray(properties) ||
    !locales[translation.locale]
  ) {
    return null;
  }

  return (
    <Accordion
      sx={{ border: "2px dotted #ddd", borderRadius: 5, p: 2 }}
      expanded={selected === translation.locale}
      onChange={() => {
        onSelect(translation.locale);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Title>
          <Avatar sx={{ mr: 3 }}>
            <LanguageOutlined />
          </Avatar>{" "}
          {locales[translation.locale] || translation.locale}
        </Title>
      </AccordionSummary>
      <AccordionDetails>
        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={2}>
              {properties.map((property) =>
                property.isMarkdown ? (
                  <MDEditor
                    style={property.style}
                    data-color-mode="light"
                    value={translation[property.name]}
                    onChange={(value) => {
                      formConfig.handleBaseChange(
                        formTranslationsKey,
                        formConfig.form[formTranslationsKey].value.map((x) =>
                          x.locale === translation.locale
                            ? {
                                ...translation,
                                [property.name]: value,
                              }
                            : x
                        )
                      );
                    }}
                  />
                ) : (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    {...property.gridOptions}
                    key={property.name}
                  >
                    <Input
                      {...property}
                      isTouched
                      isValid={
                        typeof property.isValid === "function"
                          ? property.isValid(
                              translation?.[property.name],
                              translation
                            )
                          : typeof property.isValid !== "undefined"
                          ? property.isValid
                          : true
                      }
                      value={translation[property.name]}
                      onChange={(e) => {
                        formConfig.handleBaseChange(
                          formTranslationsKey,
                          formConfig.form[formTranslationsKey].value.map((x) =>
                            x.locale === translation.locale
                              ? {
                                  ...translation,
                                  [property.name]: e.target.value,
                                }
                              : x
                          )
                        );
                      }}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
}
